import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import Button, { ButtonsThemes } from '../Button/Buttons';
import { APP_URL, TEXT } from '../../constants';
import { correctAnswers, questionsMocks } from '../../mocks/mockData';
import { IAnswer, ITestingPage } from '../../interfaces/common';

import css from './TestingForm.module.css';

const TestingForm: FC<ITestingPage> = ({ isClosed }) => {
  const [offset, setOffset] = useState<number>(0);
  const [answers, setAnswers] = useState<IAnswer>({});
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const history = useHistory();

  const textContainerClass = cx(css.TextContainer, {
    [css.IncreaseHeight]: isClosed,
  });

  const displayedQuestion = questionsMocks[offset];
  const displayQuestionId = displayedQuestion?.id;
  const answerOptions = displayedQuestion?.answers;

  const shuffleArray = (array: any[]) => {
    if (!array?.length) {
      return [];
    }

    return array.reduce(
      (acc, answer, index) => {
        const j = Math.floor(Math.random() * (index + 1));
        const temp = acc[index];
        acc[index] = acc[j];
        acc[j] = temp;

        return acc;
      },
      [...array],
    );
  };

  const sortedAnswers = useMemo(() => {
    return shuffleArray(answerOptions);
  }, [answerOptions]);

  const lastUserAnswer: boolean = Object.keys(answers).length - 1 === offset;

  const onChange = (event: any) => {
    const inputValue = event.target.value;
    setAnswers((current: IAnswer) => ({
      ...current,
      [displayedQuestion?.id as string]: {
        answerId: inputValue,
        accordance: 'new',
        correctAnswerId: 'id',
      },
    }));
  };

  const onSubmitClick = () => {
    const questionsFind = questionsMocks.find(
      (question) => question.id === displayedQuestion?.id,
    );

    const searchingSelectedChoice = questionsFind!.answers.find(
      (answer) => answer.id === answers[displayedQuestion?.id].answerId,
    );

    const searchCorrectAnswer = questionsFind!.answers.find(
      (answer) => answer.is_correct,
    );

    if (searchingSelectedChoice?.is_correct) correctAnswers.push('correct');

    setAnswers((current: IAnswer) => ({
      ...current,
      [displayedQuestion?.id as string]: {
        answerId: answers[displayedQuestion?.id].answerId,
        accordance: searchingSelectedChoice?.is_correct
          ? 'correct'
          : 'incorrect',
        correctAnswerId: searchCorrectAnswer!.id,
      },
    }));

    if (questionsFind?.isFinished) {
      setIsFinished(true);
    }
  };

  const nextQuestion = async () => {
    setOffset(offset + 1);
  };

  const prevQuestion = () => {
    setOffset(offset - 1);
  };

  const onFinishedTest = () => {
    history.push({
      pathname: APP_URL.COMPLETED,
    });
  };

  const currentAnswer = answers[displayQuestionId];

  return (
    <div className={css.TestingFormContainer}>
      <div className={textContainerClass}>
        <div className={css.QuestionWrapper}>
          <div className={css.QuestionNumber}>{offset + 1}</div>
          <div className={css.QuestionText}>
            <div> {displayedQuestion?.title}</div>
            <div>
              {displayedQuestion?.image_url && (
                <img
                  className={css.QuestionPicture}
                  src={displayedQuestion.image_url}
                  alt={TEXT.QUESTION_ALT}
                />
              )}
            </div>
          </div>
        </div>
        <div className={css.TestingForm}>
          <form className={css.FormContainer}>
            <fieldset className={css.TestingFormField}>
              {sortedAnswers?.map((answer: any) => (
                <div className={css.RadioWrapper} key={answer?.id}>
                  <input
                    className={css.RadioInput}
                    type='radio'
                    id={answer?.id}
                    name={'answer'}
                    value={answer?.id}
                    onChange={onChange}
                    checked={answer?.id === currentAnswer?.answerId}
                    disabled={
                      currentAnswer && currentAnswer.accordance !== 'new'
                    }
                  />
                  <label className={css.RadioLabel} htmlFor={answer?.id}>
                    <span
                      className={
                        currentAnswer?.correctAnswerId === answer?.id
                          ? css.GreenColor
                          : '' ||
                            (currentAnswer?.correctAnswerId === answer?.id &&
                              currentAnswer?.accordance === 'incorrect')
                          ? css.GreenColor
                          : currentAnswer?.answerId === answer?.id &&
                            currentAnswer?.accordance === 'incorrect'
                          ? css.RedColor
                          : ''
                      }
                    >
                      {answer?.title}
                    </span>
                    {answer?.imageUrl && (
                      <img
                        className={css.Image}
                        src={answer.imageUrl}
                        alt={`${TEXT.ANSWER_ALT}${sortedAnswers.indexOf(
                          answer,
                        )}`}
                      />
                    )}
                  </label>
                </div>
              ))}
              {currentAnswer?.accordance === 'incorrect' && (
                <div className={css.RadioWrapper}>
                  <span className={css.textFromNPA}>
                    {displayedQuestion?.description}
                  </span>
                </div>
              )}
            </fieldset>
          </form>
        </div>
      </div>
      <div className={css.ButtonWrapper}>
        <span className={css.CountQuestions}>{`${offset + 1}/${
          questionsMocks?.length
        }`}</span>
        {currentAnswer?.accordance === 'correct' && (
          <span className={css.RightAnswer}>{TEXT.CORRECT_ANSWER}</span>
        )}
        {currentAnswer?.accordance === 'incorrect' && (
          <span className={css.IncorrectAnswer}>{TEXT.INCORRECT_ANSWER}</span>
        )}
        <div className={css.ButtonContainer}>
          {isFinished && lastUserAnswer ? (
            <>
              <Button
                className={css.Button}
                theme={ButtonsThemes.white}
                onClick={prevQuestion}
                children={TEXT.PREV_QUESTION}
                disabled={offset <= 0}
              />
              <Button
                className={css.Button}
                onClick={onFinishedTest}
                children={TEXT.FINISH_TEST}
              />
            </>
          ) : (
            <>
              <Button
                className={css.Button}
                theme={ButtonsThemes.white}
                onClick={prevQuestion}
                children={TEXT.PREV_QUESTION}
                disabled={offset <= 0}
              />
              <Button
                className={css.Button}
                onClick={
                  currentAnswer && currentAnswer.accordance !== 'new'
                    ? nextQuestion
                    : onSubmitClick
                }
                disabled={!currentAnswer}
                children={
                  currentAnswer && currentAnswer.accordance !== 'new'
                    ? TEXT.NEXT_QUESTION
                    : TEXT.CHECK_ANSWER
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestingForm;
