import { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as TestIcon } from './icons/test-icon.svg';
import { ReactComponent as FolderIcon } from './icons/folder-icon.svg';
import SupportIcon from './icons/support.png';
import logo from '../../img/logo.png';

import css from './LeftPanel.module.css';
import { APP_URL } from '../../constants';
import { LEFT_PANEL } from '../../constants';

const LeftPanel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const containerClassName = cx(css.Container, {
    [css.ActiveContainer]: isOpen,
  });
  const linksClassName = cx(css.Links, {
    [css.ActiveLinks]: isOpen,
  });
  const burgerClassName = cx(css.Burger, {
    [css.ActiveBurger]: isOpen,
  });

  return (
    <div className={containerClassName}>
      <img className={css.Logo} src={logo} alt='Logo' />
      <div className={linksClassName}>
        <Link to={APP_URL.THEMES} className={css.Link}>
          <div className={css.Icon}>
            <TestIcon />
          </div>
          <span className={css.LinkContent}>{LEFT_PANEL.KNOWLEDGE_CHECK}</span>
        </Link>
        <Link
          to={APP_URL.SUPPORT}
          className={css.Link}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className={css.Icon}>
            <img src={SupportIcon} alt='' />
          </div>
          <span className={css.LinkContent}>{LEFT_PANEL.HELP}</span>
        </Link>
        <Link
          to={APP_URL.ABOUT}
          className={css.Link}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className={css.Icon}>
            <FolderIcon />
          </div>
          <span className={css.LinkContent}>
            {LEFT_PANEL.ABOUT_APPLICATION}
          </span>
        </Link>
      </div>
      <div
        className={burgerClassName}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span></span>
      </div>
    </div>
  );
};
// this empty span is for burger menu
export default LeftPanel;
