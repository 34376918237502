import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import About from './components/About/About';
import TestingPage from './pages/Test/TestingPage';
import CompletedPage from './pages/Completed/CompletedPage';
import KnowledgeCheck from './pages/KnowledgeCheck/KnowledgeCheck';
import QuestionGroups from './pages/QuestionGroups/QuestionGroups';
import AppContext, { appInitialState } from './constants/app-context.conts';
import { APP_URL } from './constants';
import Support from './components/Support/Support';

const App = () => (
  <AppContext.Provider value={{ ...appInitialState }}>
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Redirect to={APP_URL.THEMES} />
        </Route>
        <Route exact path={APP_URL.THEMES} component={KnowledgeCheck} />
        <Route exact path={APP_URL.TESTING_PAGE} component={TestingPage} />
        <Route exact path={APP_URL.SUPPORT} component={Support} />
        <Route exact path={APP_URL.ABOUT} component={About} />
        <Route exact path={APP_URL.COMPLETED} component={CompletedPage} />
        <Route
          exact
          path={APP_URL.QUESTION_GROUPS}
          component={QuestionGroups}
        />
      </Switch>
    </BrowserRouter>
  </AppContext.Provider>
);

export default App;
