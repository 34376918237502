import React from 'react';

import Completed from '../../components/Completed/Completed';
import { correctAnswers, questionsMocks } from '../../mocks/mockData';

const CompletedPage = () => {
  const questionsCount = questionsMocks.length;
  const rightAnswers = correctAnswers.length;

  const percent = Math.round((rightAnswers / questionsCount) * 100);

  return (
    <Completed
      percent={percent}
      questionsCount={questionsCount}
      right={rightAnswers}
    />
  );
};

export default CompletedPage;
