export const themesMocks = [
  {
    id: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    title:
      'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
    active: true,
    createdAt: '2023-01-16T23:25:52.601Z',
    updatedAt: '2023-01-16T23:25:52.601Z',
    deletedAt: null,
    categoryId: '419f00b1-b897-4d6f-be46-2291a533b7d7',
    userSubscriptions: [],
    tariffs: [
      { value: 'THREE_DAYS', label: '3 дня / 12 рублей' },
      { value: 'SEVEN_DAYS', label: '7 дней / 15 рублей' },
      { value: 'FOURTEEN_DAYS', label: '14 дней / 20 рублей' },
    ],
    isDisable: false,
  },
  {
    id: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    title:
      'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
    active: true,
    createdAt: '2023-01-16T23:25:52.601Z',
    updatedAt: '2023-01-16T23:25:52.601Z',
    deletedAt: null,
    categoryId: '419f00b1-b897-4d6f-be46-2291a533b7d7',
    userSubscriptions: [],
    tariffs: [
      { value: 'THREE_DAYS', label: '3 дня / 12 рублей' },
      { value: 'SEVEN_DAYS', label: '7 дней / 15 рублей' },
      { value: 'FOURTEEN_DAYS', label: '14 дней / 20 рублей' },
    ],
    isDisable: true,
  },
  {
    id: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    title:
      'Тесты по охране труда для руководителей и специалистов строительных организаций',
    active: true,
    createdAt: '2023-01-16T23:25:52.601Z',
    updatedAt: '2023-01-16T23:25:52.601Z',
    deletedAt: null,
    categoryId: '419f00b1-b897-4d6f-be46-2291a533b7d7',
    userSubscriptions: [],
    tariffs: [
      { value: 'THREE_DAYS', label: '3 дня / 12 рублей' },
      { value: 'SEVEN_DAYS', label: '7 дней / 15 рублей' },
      { value: 'FOURTEEN_DAYS', label: '14 дней / 20 рублей' },
    ],

    isDisable: true,
  },
  {
    id: '2675b263-e60c-4c01-90b2-ad384714e9fd',
    title:
      'Подготовка водителей механических транспортных средств для выполнения перевозки опасных грузов',
    active: true,
    createdAt: '2023-01-16T23:25:52.601Z',
    updatedAt: '2023-01-16T23:25:52.601Z',
    deletedAt: null,
    categoryId: '09c4850c-5600-48b9-aa44-954387ef2647',
    userSubscriptions: [],
    tariffs: [
      { value: 'THREE_DAYS', label: '3 дня / 15 рублей' },
      { value: 'SEVEN_DAYS', label: '7 дней / 20 рублей' },
      { value: 'FOURTEEN_DAYS', label: '14 дней / 25 рублей' },
    ],
    isDisable: true,
  },
];

export const questionGroupsMock = [
  {
    id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title: 'Электробезопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '9',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: false,
  },
  {
    id: '35277d2e-d781-443d-989e-6259a867923c',
    title: 'Инструмент слесарно-монтажный',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '17',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    isDisable: true,
  },
  {
    id: '56db7868-3c87-4902-843f-f199db48236c',
    title: 'Эксплуатация автомобилей. Техобслуживание и ремонт автомобилей',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '30',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '58be005c-2861-4e13-8476-44e3224f0a3b',
    title:
      'Системы менеджмента здоровья и безопасности при профессиональной деятельности СТБ ISO 45001-2020',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '49',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '58f873c8-9ddf-41e7-a24a-b0daf66f0ef9',
    title: 'Цвета сигнальные и знаки безопасности',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '27',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '6a09878e-07b8-4ae2-99ed-179d213dd048',
    title: 'Погрузочно-разгрузочные и складские работы',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '29',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '76853f2a-a2ee-4469-8d66-261031be804e',
    title: 'ОТ при работе на лестницах',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '10',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '9487f4db-e93d-4eff-99a6-8d42df23acc3',
    title: 'Правила расследования и учета несчастных случаев на производстве',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '15',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'b6b54acb-5a64-435d-aa51-f36e565f8730',
    title: 'Пожарная безопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '51',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'b73d5a94-634c-4b48-bd5a-baed4ac1a18f',
    title: 'Оказание первой помощи пострадавшим',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '74',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'c2c1ead7-255d-4b19-9e2f-b84c1f1be3c6',
    title: 'ОТ при работах, выполняемых по наряду-допуску',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '34',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'cc29ec0f-e593-40f9-981f-fd43ce9385d5',
    title: 'Охрана труда при работе на высоте',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '26',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'd59647a8-af8f-40b3-b972-c55ce022f8da',
    title: 'Промышленная безопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '27',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'dd6a5357-37f3-4751-8e6c-6e92733bd1d0',
    title: 'Общие вопросы по охране труда',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '170',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'ecacf553-da58-4b7c-8988-6b5934282f50',
    title: 'Основные положения трудового права',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '35',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'f6bf7ed4-8fa9-47af-a049-6575bcea9d2c',
    title: 'Инструмент ручной электромеханический',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '7cd9b1bb-16cf-425b-818a-ecf1bfd6fffc',
    questionsCount: '14',
    theme: {
      title:
        'Тесты по охране труда для инженеров по охране труда (вне зависимости от вида деятельности)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '40ddf7bf-a5e5-4a7a-8756-76e848b4bf3c',
    title:
      'Подготовка/переподготовка водителей механических транспортных средств для выполнения перевозки опасных грузов в цистернах',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '2675b263-e60c-4c01-90b2-ad384714e9fd',
    questionsCount: '171',
    theme: {
      title:
        'Подготовка водителей механических транспортных средств для выполнения перевозки опасных грузов',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'aeee650e-fcd0-4cf5-a24f-3b6ed90be51c',
    title:
      'Подготовка/переподготовка водителей механических транспортных средств для выполнения перевозки опасных грузов (базовая программа)',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '2675b263-e60c-4c01-90b2-ad384714e9fd',
    questionsCount: '386',
    theme: {
      title:
        'Подготовка водителей механических транспортных средств для выполнения перевозки опасных грузов',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '06e52479-324a-41b3-be37-dc36b3a91536',
    title: 'Правила расследования и учета несчастных случаев на производстве',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '15',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '33ed4b72-8156-4877-8015-d52bab022484',
    title: 'Погрузочно-разгрузочные и складские работы',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '29',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '449d672f-a50b-4546-9c3b-ee2fb5cefd89',
    title: 'Общие вопросы по охране труда',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '98',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '66e50f46-9717-4e5d-8f04-ef8bddfb06a6',
    title: 'Электробезопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '9',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '74b106a7-0659-4836-876f-a4a79cfadafa',
    title: 'Цвета сигнальные и знаки безопасности',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '27',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'ad074736-521e-467b-b58a-954b89a48e46',
    title: 'Пожарная безопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '45',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'd0a7f414-9a20-4613-b41d-44652aef1b40',
    title: 'Основные положения трудового права',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '48',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'f7e4f6ce-380e-4511-8b06-0158397fb892',
    title:
      'Системы менеджмента здоровья и безопасности при профессиональной деятельности СТБ ISO 45001-2020',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: '6824e5b9-5797-48b7-b3dd-e476ffef5fcd',
    questionsCount: '49',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов (кроме строительных организаций)',
      active: true,
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '1fd14bfe-14c0-404f-8df6-c9cdb1723c03',
    title: 'Охрана труда при работе на высоте',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '26',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '21fb8057-00d1-4da6-95d2-925571e7836d',
    title: 'Основные положения трудового права',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '48',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '316a7017-a7a5-47fd-a457-3628650c4cc0',
    title:
      'Системы менеджмента здоровья и безопасности при профессиональной деятельности СТБ ISO 45001-2020',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '49',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '365b81dd-663a-43ce-afe7-403b06e14f61',
    title: 'Погрузочно-разгрузочные и складские работы',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '29',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '4dd4dc35-ce50-4318-8502-b6a81640bbda',
    title: 'Инструмент слесарно-монтажный',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '17',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '68f5f740-c8bc-4cbc-9765-0bdcfb94acab',
    title: 'Инструмент ручной электромеханический',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '14',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: '7b10fd3b-1652-4bb5-8176-b88c9706ec4c',
    title: 'Эксплуатация напольного колесного безрельсового транспорта',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '15',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'a094c0ee-4f2c-4e2a-8ee3-d8cc4a60f3ce',
    title: 'Правила по охране труда при выполнении строительных работ',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '127',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'b5bf60ef-164d-48a7-8e68-4977eabfba6a',
    title: 'Электробезопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '9',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'd1aebdc9-400d-4d92-a4c2-e159239aca06',
    title: 'Инструмент пневматический',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '19',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'dc78986a-5dc2-4d18-93f8-c15cb613059f',
    title: 'Эксплуатация автомобилей. Техобслуживание и ремонт автомобилей',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '30',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'ea13a05d-a803-4667-ab02-9ac969277df7',
    title: 'Цвета сигнальные и знаки безопасности',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '27',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'f3d84ecd-ec5d-4877-93ac-6de40e83ab49',
    title: 'Пожарная безопасность',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '69',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
  {
    id: 'f3f81377-6439-4e3d-86df-0c94792693e1',
    title: 'Правила расследования и учета несчастных случаев на производстве',
    createdAt: '2023-01-16T23:25:52.605Z',
    updatedAt: '2023-01-16T23:25:52.605Z',
    deletedAt: null,
    themeId: 'ee214fff-ed59-476e-ba69-8b0b6a24ba63',
    questionsCount: '15',
    theme: {
      title:
        'Тесты по охране труда для руководителей и специалистов строительных организаций',
      active: true,
      userSubscriptions: [],
    },
    sessions: [],
    isDisable: true,
  },
];

export const questionsMocks = [
  {
    answers: [
      {
        id: '60e999c3-2640-4410-85b9-93185c137422',
        question_id: '70601381-662c-46a4-8bb1-2820038719e4',
        title: 'Немедленно без предварительного разрешения.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '64277318-a202-451b-8f34-ec4e7ada7591',
        question_id: '70601381-662c-46a4-8bb1-2820038719e4',
        title:
          'С разрешения непосредственного руководителя работ  или иного уполномоченного должностного лица (представителя  работодателя, владельца электроустановки, органа госэнергогазнадзора).',
        is_correct: false,
        image_url: null,
      },
      {
        id: '77ac08b7-e420-41ee-875b-01125ba68fb4',
        question_id: '70601381-662c-46a4-8bb1-2820038719e4',
        title:
          'После предупреждения лиц, эксплуатирующих отключаемое  электрооборудование, и лиц, которые могут получить травму в темноте после отключения освещения.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'aca671de-f4fb-4404-80cb-a2911557390b',
        question_id: '70601381-662c-46a4-8bb1-2820038719e4',
        title:
          'С разрешения лица, ответственного за электрохозяйство,  или иного лица, имеющего квалификационную группу  по электробезопасности ни ниже IV.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: '70601381-662c-46a4-8bb1-2820038719e4',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Как производить при несчастных случаях снятие напряжения для освобождения потерпевшего от воздействия электрического тока?',
    description:
      'ТКП-427-2022 Технический кодекс установившейся практики  «Электроустановки. Правила по обеспечению безопасности при эксплуатации»  (утв. пост. Министерства энергетики РБ от 09.03.2022 № 10) (п. 4.1.3)_x000D_\nТиповая инструкция по охране труда для электромонтера по ремонту и обслуживанию электрооборудования  (утв. пост. Министерства труда и социальной защиты РБ и Министерства энергетики РБ 26.12.2017 № 91/53) (п.39)',
    image_url: null,
  },
  {
    answers: [
      {
        id: '309b2840-4d1d-40ab-8542-e029232aae2d',
        question_id: 'f7a4fcf5-deaf-4b90-be36-052b4df848da',
        title: 'Не разрешается.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '7318d327-eb93-4693-bc74-ecd4568a6c21',
        question_id: 'f7a4fcf5-deaf-4b90-be36-052b4df848da',
        title: 'Разрешается, без всяких условий.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'cebf65a8-571a-4fec-9229-6cad48c77120',
        question_id: 'f7a4fcf5-deaf-4b90-be36-052b4df848da',
        title:
          'Разрешается, если им присвоена группа по электробезопасности IV.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'b7617891-c0d7-4263-8da2-ea920ed27806',
        question_id: 'f7a4fcf5-deaf-4b90-be36-052b4df848da',
        title:
          'Разрешается, под контролем лица ответственного за электрохозяйство.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: 'f7a4fcf5-deaf-4b90-be36-052b4df848da',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Разрешается ли допускать лиц, не достигших 18-летнего возраста, к самостоятельным работам в электроустановках?',
    description:
      'ТКП-427-2022 Технический кодекс установившейся практики  «Электроустановки. Правила по обеспечению безопасности при эксплуатации»  (утв. пост. Министерства энергетики РБ от 09.03.2022 № 10) (п. 4.2.3)',
    image_url: null,
  },
  {
    answers: [
      {
        id: '7e052e7f-fd79-4199-a90a-a6ad7701c5e3',
        question_id: 'eb80a95f-7cc9-490c-bcf3-d3de5a837d62',
        title:
          'Запись в удостоверении по охране труда на право выполнения специальных работ.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '53b60d77-325a-439b-b3fd-8ddc7d916cc4',
        question_id: 'eb80a95f-7cc9-490c-bcf3-d3de5a837d62',
        title:
          'Письменное разрешение лица ответственного за электрохозяйство на право выполнения специальных работ.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '437e2809-804e-4f57-8b8d-8481f9e3ada0',
        question_id: 'eb80a95f-7cc9-490c-bcf3-d3de5a837d62',
        title:
          'Письменное разрешение органа госэнергогазнадзора на право  выполнения специальных работ.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'dccf8e13-b073-4348-a61c-67044db4f10f',
        question_id: 'eb80a95f-7cc9-490c-bcf3-d3de5a837d62',
        title: 'Специальные работы выполняются без дополнительных условий.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: 'eb80a95f-7cc9-490c-bcf3-d3de5a837d62',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Что должны иметь лица из электротехнического персонала, обладающие правом проведения работ, к которым предъявляются специальные требования по охране труда?',
    description:
      'ТКП-427-2022 Технический кодекс установившейся практики  «Электроустановки. Правила по обеспечению безопасности при эксплуатации»  (утв. пост. Министерства энергетики РБ от 09.03.2022 № 10) (п. 4.2.7)',
    image_url: null,
  },
  {
    answers: [
      {
        id: 'e900f72f-0b55-4389-81c4-6442b6b1eed1',
        question_id: 'd5440cba-e817-4071-8e82-550eab1ed61e',
        title: 'На руководителя Потребителя.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '9c1924a8-6d4e-4b81-81e3-5a6d8d8cd49d',
        question_id: 'd5440cba-e817-4071-8e82-550eab1ed61e',
        title: 'На работников потребителя, эксплуатирующих электроустановки.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '1af3ae44-3bc8-4b5a-846b-36a91e30d9ef',
        question_id: 'd5440cba-e817-4071-8e82-550eab1ed61e',
        title:
          'На работников потребителя, материально ответственных за электроустановки.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'ee7fb125-4bab-42bf-acd1-20193730045a',
        question_id: 'd5440cba-e817-4071-8e82-550eab1ed61e',
        title: 'На энергоснабжающую организацию.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: 'd5440cba-e817-4071-8e82-550eab1ed61e',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'У Потребителей, электрохозяйство которых включает в себя только вводное (вводно-распределительное) устройство, осветительные установки, электрооборудование номинальным напряжением не выше 380 В, с разрешенной к использованию мощностью до 30 кВт, ответственный за электрохозяйство может не назначаться._x000D_\nНа кого в этом случае возлагается ответственность за безопасную эксплуатацию электроустановок?',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.4.1.10)',
    image_url: null,
  },
  {
    answers: [
      {
        id: '56df9e14-31e5-48a2-955c-15a07137519f',
        question_id: 'c84ef143-ef24-423c-bd39-4ddd55c9df09',
        title:
          'Пройти инструктаж и оформить соответствующее заявление- обязательство в территориальном органе госэнергогазнадзора.',
        is_correct: true,
        image_url: null,
      },
      {
        id: 'aacfd71e-5dcd-4e30-868e-be64cd06c9e9',
        question_id: 'c84ef143-ef24-423c-bd39-4ddd55c9df09',
        title: 'Направить в орган госэнергогазнадзора уведомительное письмо.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '64e2a5ed-3743-4b46-b79d-934ceff8308a',
        question_id: 'c84ef143-ef24-423c-bd39-4ddd55c9df09',
        title:
          'Пройти инструктаж и оформить соответствующее заявление- обязательство в государственной инспекции труда.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'a463a8e0-9e57-4243-8643-588acdb38bf4',
        question_id: 'c84ef143-ef24-423c-bd39-4ddd55c9df09',
        title:
          'Направить в орган госэнергогазнадзора копию приказа  о назначении лица, ответственного за электрохозяйство.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: 'c84ef143-ef24-423c-bd39-4ddd55c9df09',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Что необходимо сделать потребителям, электрохозяйство которых включает в себя только вводное (вводно-распределительное) устройство, осветительные установки, электрооборудование номинальным напряжением не выше 380 В, с разрешенной к использованию мощностью до 30 кВт, возложивших на себя обязанности за безопасную эксплуатацию электроустановок?',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п. 4.1.10)',
    image_url: null,
  },
  {
    answers: [
      {
        id: 'b7355fcc-649a-442c-b0b6-d39d7f59605a',
        question_id: 'd2a2dc19-8696-48e8-a0cd-06d746254a4c',
        title:
          '... своему непосредственному руководителю,  а в его отсутствие - вышестоящему руководителю.',
        is_correct: true,
        image_url: null,
      },
      {
        id: 'fe7ac2c1-6a96-4849-ae10-1eaa6dc04e61',
        question_id: 'd2a2dc19-8696-48e8-a0cd-06d746254a4c',
        title: '... лицу, ответственному за электрохозяйство.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '4a6b5885-ac02-4b4f-ae83-0156824bda38',
        question_id: 'd2a2dc19-8696-48e8-a0cd-06d746254a4c',
        title:
          '... специалисту по охране труда, а в его отсутствие - общественному  инспектору по охране труда или в профсоюзный комитет.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'dfd9f110-159e-4274-962c-a7db16efd5c6',
        question_id: 'd2a2dc19-8696-48e8-a0cd-06d746254a4c',
        title: '... в орган госэнергогазнадзора.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: 'd2a2dc19-8696-48e8-a0cd-06d746254a4c',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Каждый работник, обнаруживший нарушения Правил технической эксплуатации электроустановок, а также заметивший неисправности электроустановки или средств защиты, должен немедленно сообщить об этом ...',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.4.1.19)',
    image_url: null,
  },
  {
    answers: [
      {
        id: '5eb32301-4235-416c-b6ca-e04e03ff95a8',
        question_id: '5f16e6ef-8664-470e-8077-294d195dbdc5',
        title: 'Все лица указанные в других вариантах ответа.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '14aa45b9-bf61-43f8-8816-94d6446f7deb',
        question_id: '5f16e6ef-8664-470e-8077-294d195dbdc5',
        title:
          'Работники, непосредственно нарушившие требования безопасности или инструкции по охране труда.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '9131dbec-67cb-4936-bc2f-c7562b22774e',
        question_id: '5f16e6ef-8664-470e-8077-294d195dbdc5',
        title: 'Руководитель Потребителя.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '07697337-ee23-4c49-84c4-b4d2d294496e',
        question_id: '5f16e6ef-8664-470e-8077-294d195dbdc5',
        title:
          'Лица, ответственные за электрохозяйство Потребителя и его  структурных подразделений.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: '5f16e6ef-8664-470e-8077-294d195dbdc5',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Кто несет ответственность за несчастные случаи, происшедшие на производстве?',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п. 4.1.22)',
    image_url: null,
  },
  {
    answers: [
      {
        id: 'cd2fe1f1-1c51-41aa-8f93-cd895f1c54df',
        question_id: '654f04c7-2096-4499-9c02-62abcf8208e2',
        title:
          '... иметь представление об опасности поражения электрическим током  и правилах оказания первой помощи потерпевшим.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '35243624-2e04-4d05-bc66-2ab412ff1c40',
        question_id: '654f04c7-2096-4499-9c02-62abcf8208e2',
        title:
          '... иметь квалификационную группу по электробезопасности не ниже II.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'abb39e5b-9a72-433d-9d7e-21091c430fc6',
        question_id: '654f04c7-2096-4499-9c02-62abcf8208e2',
        title:
          '... проходить периодическое медицинское освидетельствование  на предмет стойкости организма к поражающим факторам электрического тока.',
        is_correct: false,
        image_url: null,
      },
      {
        id: 'e72a28fe-ff14-4322-bad4-e2035b5b4d6d',
        question_id: '654f04c7-2096-4499-9c02-62abcf8208e2',
        title: '... знать электротехнику в объеме школьной программы.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: '654f04c7-2096-4499-9c02-62abcf8208e2',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Неэлектротехнический персонал, выполняющий работу, при которой может возникнуть опасность поражения электрическим током, должен ...',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.4.2.8)',
    image_url: null,
  },
  {
    answers: [
      {
        id: '5749f040-9a3d-419a-ae8e-00dd4c0dadd9',
        question_id: 'b874fb8c-eeff-41d1-95b0-8d00f88037fe',
        title:
          '... пройти вводный инструктаж по охране труда, с учетом  требований к персоналу с группой по электробезопасности I.',
        is_correct: true,
        image_url: null,
      },
      {
        id: '82202602-5ad7-424e-8a07-9e1151090af8',
        question_id: 'b874fb8c-eeff-41d1-95b0-8d00f88037fe',
        title:
          '... пройти проверку знаний в объеме I группы по электробезопасности.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '3666aee5-a48e-4c1c-aa20-8a7aab084f7f',
        question_id: 'b874fb8c-eeff-41d1-95b0-8d00f88037fe',
        title:
          '... пройти обучение по программе I группы по электробезопасности.',
        is_correct: false,
        image_url: null,
      },
      {
        id: '694a9cb9-50fe-4e7e-ac10-6304ed3aca7d',
        question_id: 'b874fb8c-eeff-41d1-95b0-8d00f88037fe',
        title:
          '... быть предупрежден под роспись об ответственности за нарушение требований электробезопасности.',
        is_correct: false,
        image_url: null,
      },
    ],
    id: 'b874fb8c-eeff-41d1-95b0-8d00f88037fe',
    question_group_id: '8e3165e4-8555-47a3-b0a1-0821030ad283',
    title:
      'Неэлектротехнический персонал, выполняющий работу, при которой может возникнуть опасность поражения электрическим током, при приеме на работу должен ...',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.п. 4.2.8, 4.2.9)',
    image_url: null,
    isFinished: true,
  },
];

export const categoriesMocks = [
  {
    id: '419f00b1-b897-4d6f-be46-2291a533b7d7',
    title: 'Охрана труда',
    createdAt: '2022-07-24T17:52:51.879Z',
    updatedAt: '2022-07-24T17:52:51.879Z',
    deletedAt: null,
  },
  {
    id: '09c4850c-5600-48b9-aa44-954387ef2647',
    title: 'Перевозка опасных грузов (ADR)',
    createdAt: '2023-01-16T23:25:52.597Z',
    updatedAt: '2023-01-16T23:25:52.597Z',
    deletedAt: null,
  },
];

export const getQuestionsMock = [
  {
    id: '60566bda-2696-43f7-b134-89b7117c7657',
    title:
      'Как производить при несчастных случаях снятие напряжения для освобождения потерпевшего от воздействия электрического тока?',
    description:
      'ТКП-427-2022 Технический кодекс установившейся практики  «Электроустановки. Правила по обеспечению безопасности при эксплуатации»  (утв. пост. Министерства энергетики РБ от 09.03.2022 № 10) (п. 4.1.3)_x000D_\nТиповая инструкция по охране труда для электромонтера по ремонту и обслуживанию электрооборудования  (утв. пост. Министерства труда и социальной защиты РБ и Министерства энергетики РБ 26.12.2017 № 91/53) (п.39)',
    imageUrl: null,
    answers: [
      {
        id: '2f685525-7c93-43ca-ad0a-01943161f255',
        title: 'Немедленно без предварительного разрешения.',
        description: null,
        imageUrl: null,
      },
      {
        id: '53b30a24-9ee9-49d2-9908-0a720d3b3f40',
        title:
          'С разрешения непосредственного руководителя работ  или иного уполномоченного должностного лица (представителя  работодателя, владельца электроустановки, органа госэнергогазнадзора).',
        description: null,
        imageUrl: null,
      },
      {
        id: '816be95c-9f3f-45f0-845b-29ba17e1986b',
        title:
          'После предупреждения лиц, эксплуатирующих отключаемое  электрооборудование, и лиц, которые могут получить травму в темноте после отключения освещения.',
        description: null,
        imageUrl: null,
      },
      {
        id: '6c6885b1-2f54-4634-9939-9e582e3d0ad5',
        title:
          'С разрешения лица, ответственного за электрохозяйство,  или иного лица, имеющего квалификационную группу  по электробезопасности ни ниже IV.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: '544e46f9-934e-4274-95f7-766daa25d3ee',
    title:
      'Разрешается ли допускать лиц, не достигших 18-летнего возраста, к самостоятельным работам в электроустановках?',
    description:
      'ТКП-427-2022 Технический кодекс установившейся практики  «Электроустановки. Правила по обеспечению безопасности при эксплуатации»  (утв. пост. Министерства энергетики РБ от 09.03.2022 № 10) (п. 4.2.3)',
    imageUrl: null,
    answers: [
      {
        id: 'e1cd5f74-ede0-468a-bef3-c8c931715707',
        title: 'Не разрешается.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'b930e0c8-e5df-4d8b-9997-7171fb4a716c',
        title: 'Разрешается, без всяких условий.',
        description: null,
        imageUrl: null,
      },
      {
        id: '404d671a-900f-4bca-a691-6ec4985b340a',
        title:
          'Разрешается, если им присвоена группа по электробезопасности IV.',
        description: null,
        imageUrl: null,
      },
      {
        id: '502a685d-338c-4742-a187-367f0f963b2c',
        title:
          'Разрешается, под контролем лица ответственного за электрохозяйство.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: 'd6953d52-2cfe-4a83-9c05-2725637f49c8',
    title:
      'Что должны иметь лица из электротехнического персонала, обладающие правом проведения работ, к которым предъявляются специальные требования по охране труда?',
    description:
      'ТКП-427-2022 Технический кодекс установившейся практики  «Электроустановки. Правила по обеспечению безопасности при эксплуатации»  (утв. пост. Министерства энергетики РБ от 09.03.2022 № 10) (п. 4.2.7)',
    imageUrl: null,
    answers: [
      {
        id: '0dbd1b5d-8279-42f8-9a25-c08f87e29edd',
        title:
          'Запись в удостоверении по охране труда на право выполнения специальных работ.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'b08cd429-9f90-4681-a5f2-70c7f8bfef7a',
        title:
          'Письменное разрешение лица ответственного за электрохозяйство на право выполнения специальных работ.',
        description: null,
        imageUrl: null,
      },
      {
        id: '212a13ba-259e-4937-9913-fe72c12109a9',
        title:
          'Письменное разрешение органа госэнергогазнадзора на право  выполнения специальных работ.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'cbd5a307-e30a-4d15-a129-663f790d0450',
        title: 'Специальные работы выполняются без дополнительных условий.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: '22ea6754-695b-424e-923c-ff54166a54ad',
    title:
      'У Потребителей, электрохозяйство которых включает в себя только вводное (вводно-распределительное) устройство, осветительные установки, электрооборудование номинальным напряжением не выше 380 В, с разрешенной к использованию мощностью до 30 кВт, ответственный за электрохозяйство может не назначаться._x000D_\nНа кого в этом случае возлагается ответственность за безопасную эксплуатацию электроустановок?',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.4.1.10)',
    imageUrl: null,
    answers: [
      {
        id: 'bbf0e7a9-b307-4f0f-8148-5eb4ad442c75',
        title: 'На руководителя Потребителя.',
        description: null,
        imageUrl: null,
      },
      {
        id: '24d96528-5153-486a-85d4-6f0d9eaa26e8',
        title: 'На работников потребителя, эксплуатирующих электроустановки.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'df4928b6-34c4-462a-8e16-8e0b03ea69e9',
        title:
          'На работников потребителя, материально ответственных за электроустановки.',
        description: null,
        imageUrl: null,
      },
      {
        id: '65f96316-cd9b-42d0-ad63-f937c27f73e0',
        title: 'На энергоснабжающую организацию.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: 'dfce2381-338c-4957-adf5-5a42de6d4efa',
    title:
      'Что необходимо сделать потребителям, электрохозяйство которых включает в себя только вводное (вводно-распределительное) устройство, осветительные установки, электрооборудование номинальным напряжением не выше 380 В, с разрешенной к использованию мощностью до 30 кВт, возложивших на себя обязанности за безопасную эксплуатацию электроустановок?',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п. 4.1.10)',
    imageUrl: null,
    answers: [
      {
        id: '295a3215-3d1a-4cec-9213-0b332bf57d03',
        title:
          'Пройти инструктаж и оформить соответствующее заявление- обязательство в территориальном органе госэнергогазнадзора.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'c7848ce3-1744-4a62-8ec4-22770e4c0bf0',
        title: 'Направить в орган госэнергогазнадзора уведомительное письмо.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'be80adf7-04c9-4ad2-b5ae-7edf9d8068c2',
        title:
          'Пройти инструктаж и оформить соответствующее заявление- обязательство в государственной инспекции труда.',
        description: null,
        imageUrl: null,
      },
      {
        id: '3f7bf2c8-38af-4cf8-9956-b6b7a9c1ac17',
        title:
          'Направить в орган госэнергогазнадзора копию приказа  о назначении лица, ответственного за электрохозяйство.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: '599bc433-2a83-4b87-b9b7-7905d82c38f8',
    title:
      'Каждый работник, обнаруживший нарушения Правил технической эксплуатации электроустановок, а также заметивший неисправности электроустановки или средств защиты, должен немедленно сообщить об этом ...',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.4.1.19)',
    imageUrl: null,
    answers: [
      {
        id: 'b3ca7577-10c0-4bd5-8504-796c5c1e67a5',
        title:
          '... своему непосредственному руководителю,  а в его отсутствие - вышестоящему руководителю.',
        description: null,
        imageUrl: null,
      },
      {
        id: '35b7bf46-1dfd-4e14-bfe1-1e94741bf700',
        title: '... лицу, ответственному за электрохозяйство.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'ffc71a6f-5b67-4546-b1f8-b15e3af4d146',
        title:
          '... специалисту по охране труда, а в его отсутствие - общественному  инспектору по охране труда или в профсоюзный комитет.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'e04ce22f-90de-44ac-9486-02e7d33b718d',
        title: '... в орган госэнергогазнадзора.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: 'f13c6cbb-b76f-4cf7-8d81-1dceef3dfed5',
    title:
      'Кто несет ответственность за несчастные случаи, происшедшие на производстве?',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п. 4.1.22)',
    imageUrl: null,
    answers: [
      {
        id: 'f1ded1ff-cb45-4259-b315-8552c2f5bd29',
        title: 'Все лица указанные в других вариантах ответа.',
        description: null,
        imageUrl: null,
      },
      {
        id: '75629a00-14c3-4fa6-9b49-817bc805a328',
        title:
          'Работники, непосредственно нарушившие требования безопасности или инструкции по охране труда.',
        description: null,
        imageUrl: null,
      },
      {
        id: '8d057ea2-172a-441e-a535-636917671ecb',
        title: 'Руководитель Потребителя.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'a0f3cd7d-f733-4048-8270-4aa264a5801c',
        title:
          'Лица, ответственные за электрохозяйство Потребителя и его  структурных подразделений.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: '4965ed34-f8b8-4e9a-bc38-9b060aab4a61',
    title:
      'Неэлектротехнический персонал, выполняющий работу, при которой может возникнуть опасность поражения электрическим током, должен ...',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.4.2.8)',
    imageUrl: null,
    answers: [
      {
        id: '0799d310-2445-44a2-8923-7e27c77aaf44',
        title:
          '... иметь представление об опасности поражения электрическим током  и правилах оказания первой помощи потерпевшим.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'c4543f17-2c7e-445d-ae1a-025c1f4d27de',
        title:
          '... иметь квалификационную группу по электробезопасности не ниже II.',
        description: null,
        imageUrl: null,
      },
      {
        id: 'ed4fb73c-5d34-40cd-ad89-9c1b1570623f',
        title:
          '... проходить периодическое медицинское освидетельствование  на предмет стойкости организма к поражающим факторам электрического тока.',
        description: null,
        imageUrl: null,
      },
      {
        id: '8dcd256b-a0d2-49c3-bf9e-59e7517c585f',
        title: '... знать электротехнику в объеме школьной программы.',
        description: null,
        imageUrl: null,
      },
    ],
  },
  {
    id: '78881d4f-ab32-4e7d-ac7b-49b542fa16d2',
    title:
      'Неэлектротехнический персонал, выполняющий работу, при которой может возникнуть опасность поражения электрическим током, при приеме на работу должен ...',
    description:
      'ТКП 181-2009 (02230)  Правила технической эксплуатации электроустановок потребителей (утв.пост. Министерства энергетики РБ от 20.05.2009 №16 Изменение № 1 утв.пост. Министерства энергетики РБ от 11.03.2014 № 6 Изменение № 2  утв.пост. Министерства энергетики РБ от 27.11. 2018 № 43 Изменение № 3  утв.пост. Министерства энергетики РБ от 10.02. 2021 № 5 Изменение № 4  утв.пост. Министерства энергетики РБ от 14.05. 2021 № 28) (п.п. 4.2.8, 4.2.9)',
    imageUrl: null,
    answers: [
      {
        id: 'c4ab7058-04f1-40f2-bc2e-756de56997aa',
        title:
          '... пройти вводный инструктаж по охране труда, с учетом  требований к персоналу с группой по электробезопасности I.',
        description: null,
        imageUrl: null,
      },
      {
        id: '1512f666-5446-4a09-9d50-edabe4cda940',
        title:
          '... пройти проверку знаний в объеме I группы по электробезопасности.',
        description: null,
        imageUrl: null,
      },
      {
        id: '8754fca8-43d2-4dbf-a4b1-5319c1f474cd',
        title:
          '... пройти обучение по программе I группы по электробезопасности.',
        description: null,
        imageUrl: null,
      },
      {
        id: '58ba5f8a-8d2c-456d-94cc-162873dde95f',
        title:
          '... быть предупрежден под роспись об ответственности за нарушение требований электробезопасности.',
        description: null,
        imageUrl: null,
      },
    ],
  },
];

export const citiesMocks = [
  { id: '9cd7e1df-79ca-4fa7-aeda-98a4c998ab24', title: 'Гродно' },
  { id: 'be4e1fef-e58a-4d85-bf3d-6224a95b3733', title: 'Минск' },
  { id: '728cb940-463f-4d1c-81b3-48f964caf743', title: 'Витебск' },
  { id: '10d317a9-75a7-4083-8091-b78822d19209', title: 'Гомель' },
  { id: '556c6223-562c-4772-840c-f5bd867f2d3a', title: 'Брест' },
  { id: 'f536623c-f712-4534-96cb-94e52f2d1646', title: 'Могилев' },
];

export const correctAnswers: string[] = [];
