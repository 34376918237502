import { createContext, useContext } from 'react';
import { ICategories, ICities, IQuestion, IThemes } from '../interfaces/common';
import { categoriesMocks, citiesMocks, themesMocks } from '../mocks/mockData';

export type ContextValue = any;

export type ContextValueSetter = (field: string, value: ContextValue) => void;

export interface IAppProps {
  themes: IThemes[];
  questions: IQuestion[];
  cities: ICities[];
  categories: ICategories[];
  setContextValue?: ContextValueSetter;
}

export const appInitialState = {
  questions: [] as IQuestion[],
  cities: citiesMocks as ICities[],
  categories: categoriesMocks as ICategories[],
  themes: themesMocks as IThemes[],
};

export const useAppContext = (): IAppProps => useContext(AppContext);

const AppContext = createContext(appInitialState);

export default AppContext;
