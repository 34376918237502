import { render } from 'react-dom';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';

import App from './App';

import './index.css';

const gtmCode: string = process.env.REACT_APP_GTM_CODE as string;

const tagManagerArgs = {
  gtmId: gtmCode,
};

TagManager.initialize(tagManagerArgs);

const root = document.createElement('div');
document.querySelector('body')?.appendChild(root);

render(
  <div>
    <App />
    <ToastContainer />
  </div>,
  root,
);
