export const KEYS = {
  CATEGORY_ID: 'categoryId',
  CITY_ID: 'cityId',
};

export const APP_URL = {
  THEMES: '/themes',
  TESTING_PAGE: '/question-group/:id',
  ABOUT: '/about',
  SUPPORT: '/support',
  QUESTION_GROUPS: '/themes/:id',
  QUESTION_GROUP: '/question-group',
  COMPLETED: '/completed',
};

export const BUTTONS_TITLE = {
  EXIT: 'Выйти',
  CANCEL: 'Отмена',
  CLOSE: 'Закрыть',
  BUY: 'Купить',
};

export const DESCRIPTION_MESSAGES = {
  TEST_INFO_FIRST_MESSAGE:
    'Каждый вопрос содержит не менее 4 ответов, один из которых является правильным.',
  TEST_INFO_SECOND_MESSAGE:
    'При выборе неправильного ответа, мы подготовили для Вас возможность ознакомиться с извлечениями из нормативных правовых актов, касающихся заданного вопроса.',
};

export const PLACEHOLDERS = {
  CHOSE_CATEGORY: 'Выберите категорию',
  CHOSE_CITY: 'Выберите город',
};

export const TITLE_PAGES = {
  KNOWLEDGE_CHECK: 'Проверка знаний',
  TESTS: 'Тесты',
  HELP: 'Помощь',
  COMPLETED_TEST: 'ТЕСТ ЗАВЕРШЕН',
};

export const LEFT_PANEL = {
  KNOWLEDGE_CHECK: TITLE_PAGES.KNOWLEDGE_CHECK,
  HELP: TITLE_PAGES.HELP,
  ABOUT_APPLICATION: 'О приложении',
};

export const TEXT = {
  QUESTION_ALT: 'Изображение для вопроса',
  ANSWER_ALT: 'Изображение для ответа №',
  FINISH_TEST: 'Завершить тест',
  NEXT_QUESTION: 'Следующий вопрос',
  PREV_QUESTION: 'Предыдущий вопрос',
  CHECK_ANSWER: 'Проверить',
  CORRECT_ANSWER: 'Ответ правильный',
  INCORRECT_ANSWER: 'Ответ неправильный',
};
