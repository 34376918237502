import React, { FC } from 'react';

import Modal from '../Modal/Modal';

import { ReactComponent as Cross } from '../../img/icons/cross.svg';
import { ReactComponent as Info } from '../../img/icons/information.svg';

import { DESCRIPTION_MESSAGES } from '../../constants';

import css from './InfoModal.module.css';

export interface IModalRelocationForm {
  onCloseModal: () => void;
  isDisplay: boolean;
}

const InfoModal: FC<IModalRelocationForm> = ({ onCloseModal, isDisplay }) => (
  <Modal onClose={onCloseModal} display={isDisplay}>
    <div className={css.WrapperModalItems}>
      <div className={css.WrapperHeader}>
        <Info className={css.InfoSVG} />
        <Cross className={css.Cross} onClick={onCloseModal} />
      </div>
      <div className={css.InformTextModal}>
        <span>{DESCRIPTION_MESSAGES.TEST_INFO_FIRST_MESSAGE}</span>
        <span>{DESCRIPTION_MESSAGES.TEST_INFO_SECOND_MESSAGE}</span>
      </div>
    </div>
  </Modal>
);

export default InfoModal;
