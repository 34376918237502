import PageWrapper from '../MainPageWrapper/PageWrapper';

import { aboutApp } from './About.const';

import css from './About.module.css';

const About = () => {
  return (
    <PageWrapper>
      <h1 className={css.Title}>
        Веб-тренажёр для подготовки к сдаче экзаменов по охране труда
      </h1>
      <div
        className={css.Text}
        dangerouslySetInnerHTML={{ __html: aboutApp }}
      />
    </PageWrapper>
  );
};

export default About;
