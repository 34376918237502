import React, { FC } from 'react';
import css from './OneTest.module.css';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../constants';
import { IQuestionGroup } from '../../interfaces/common';

const OneTest: FC<{ test: IQuestionGroup }> = ({ test }) => {
  return (
    <div className={css.Container}>
      <p
        className={css.Count}
      >{`Количество вопросов: ${test?.questionsCount}`}</p>
      <p className={css.TestName}>{test.title}</p>
      <div className={css.ButtonForDoneTest}>
        <span className={css.Description}>{`Количество попыток: ${0}`}</span>
        {
          <div className={css.ButtonWrapper}>
            {test.isDisable ? (
              <Link
                to={''}
                className={css.LinkDisable}
                onClick={(event) => event.preventDefault()}
              >
                Начать
              </Link>
            ) : (
              <Link
                to={`${APP_URL.QUESTION_GROUP}/${test.id}`}
                className={css.Link}
              >
                Начать
              </Link>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default OneTest;
