import { useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';

import PageWrapper from '../../components/MainPageWrapper/PageWrapper';
import Button, { ButtonsThemes } from '../../components/Button/Buttons';
import { useAppContext } from '../../constants/app-context.conts';
import { convertToSelectOptions } from '../../helpers/convert-data-to-select-options';
import { ICategories, ISelectOptions, IThemes } from '../../interfaces/common';
import { APP_URL } from '../../constants';
import Modal from '../../components/Modal/Modal';
import {
  BUTTONS_TITLE,
  KEYS,
  PLACEHOLDERS,
  TITLE_PAGES,
} from '../../constants';
import { themesMocks } from '../../mocks/mockData';

import css from './KnowledgeCheck.module.css';

const KnowledgeCheck = () => {
  const [isClosedQuitModal, setIsClosedQuitModal] = useState(false);
  const { categories, themes } = useAppContext();

  const history = useHistory();
  const location = useLocation();

  const isShowQuitModal = () => setIsClosedQuitModal((prev) => !prev);

  const onQueryChange = (name: string, e: SingleValue<ISelectOptions>) => {
    const params = new URLSearchParams(location.search);

    if (params.toString().includes(name)) {
      params.delete(name);
    }

    params.append(name, e!.value);

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const categoryOptions = convertToSelectOptions(categories as ICategories[]);

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const categoryId = query.get(KEYS.CATEGORY_ID);

  const filteringThemes = themes.filter(
    (e: IThemes) => e.categoryId === categoryId,
  );

  const initialThemes = categoryId ? filteringThemes : themesMocks;

  const defaultCategoryOption = categoryOptions.filter(
    (el) => el.value === categoryId,
  )[0];

  return (
    <PageWrapper>
      <div className={css.SelectWrapper}>
        <h1 className={css.Title}>{TITLE_PAGES.KNOWLEDGE_CHECK}</h1>
        <div className={css.Section}>
          <div className={css.SelectContainer}>
            <span className={css.CategoryName}>
              {PLACEHOLDERS.CHOSE_CATEGORY}:
            </span>
          </div>
          <div className={css.SelectContainer}>
            <Select
              options={categoryOptions}
              value={defaultCategoryOption}
              placeholder={PLACEHOLDERS.CHOSE_CATEGORY}
              className={css.Select}
              onChange={(e) => onQueryChange(KEYS.CATEGORY_ID, e)}
            />
          </div>
        </div>
        <span className={css.Specialization}>Специализация</span>
        <div className={css.SpecWrapper}>
          {initialThemes.map((el) => {
            return (
              <div className={css.SpecContainer} key={el.id}>
                <p className={css.SpecName}>{el.title}</p>
                <p className={css.ChooseRate}>Выберите тариф</p>
                <div className={css.RateSelectWrapper}>
                  <div className={css.RateFlex}>
                    <Select
                      defaultValue={el.tariffs[0]}
                      options={el.tariffs}
                      className={css.RateSelect}
                      onChange={(option) => option as any}
                    />
                    <Button
                      onClick={() => isShowQuitModal()}
                      children={BUTTONS_TITLE.BUY}
                    />
                  </div>
                  <Link to={`${APP_URL.THEMES}/${el.id}`} className={css.Link}>
                    Просмотр
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal onClose={isShowQuitModal} display={isClosedQuitModal}>
        <div className={css.ModalContent}>
          <p className={css.ModalDesc}>
            Для получения доступа к тестам, вам необходимо{' '}
            {
              <a
                href='https://bilet.rabt.by/sign-in'
                target='_blank'
                rel='noreferrer'
              >
                войти
              </a>
            }{' '}
            или{' '}
            <a
              href='https://bilet.rabt.by/sign-up'
              target='_blank'
              rel='noreferrer'
            >
              зарегистрироваться
            </a>{' '}
            в системе.
          </p>
          <div className={css.ButtonContainer}>
            <Button
              children={BUTTONS_TITLE.CLOSE}
              theme={ButtonsThemes.white}
              onClick={isShowQuitModal}
              className={css.QuitLink}
            />
          </div>
        </div>
      </Modal>
    </PageWrapper>
  );
};

export default KnowledgeCheck;
