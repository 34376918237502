import { Link } from 'react-router-dom';
import { useState } from 'react';

import InfoModal from '../../components/InfoModal/InfoModal';
import TestingForm from '../../components/TestingForm/TestingForm';

import { ReactComponent as Cross } from '../../img/icons/cross.svg';
import { ReactComponent as Info } from '../../img/icons/information.svg';

import { APP_URL } from '../../constants';
import { BUTTONS_TITLE } from '../../constants';

import css from './Test.module.css';

const TestingPage = () => {
  const [isClosedInfoModal, setIsClosedInfoModal] = useState(false);

  const isShowInfoModal = () => setIsClosedInfoModal((prev) => !prev);

  return (
    <div className={css.Container}>
      <div className={css.WrapperNavigation}>
        <Info className={css.Info} onClick={isShowInfoModal} />

        <Link to={APP_URL.THEMES} className={css.Close}>
          <Cross className={css.BigCross} />
          <span className={css.Quit}>{BUTTONS_TITLE.EXIT}</span>
        </Link>
      </div>
      <InfoModal onCloseModal={isShowInfoModal} isDisplay={isClosedInfoModal} />
      <TestingForm isClosed={isClosedInfoModal} />
    </div>
  );
};

export default TestingPage;
