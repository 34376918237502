import React from 'react';

import Tests from '../../components/Tests/Tests';
import { useParams } from 'react-router-dom';
import { questionGroupsMock } from '../../mocks/mockData';
import { ID } from '../../interfaces/common';

const QuestionGroups = () => {
  const { id } = useParams<ID>();

  const questionGroups = questionGroupsMock.filter(
    (group) => group.themeId === id,
  );

  return <Tests questionGroups={questionGroups} />;
};

export default QuestionGroups;
