import { FC } from 'react';

import OneTest from '../OneTestBlock/OneTest';
import PageWrapper from '../MainPageWrapper/PageWrapper';
import { IQuestionGroup, ITests } from '../../interfaces/common';
import { TITLE_PAGES } from '../../constants';

import css from './Tests.module.css';

const Tests: FC<ITests> = ({
  questionGroups,
}: {
  questionGroups: IQuestionGroup[];
}) => {
  return (
    <PageWrapper>
      <div className={css.TitleContainer}>
        <h1 className={css.Title}>{TITLE_PAGES.TESTS}</h1>
      </div>
      <div className={css.TestsWrapper}>
        <span className={css.Specialization}>
          {questionGroups[0]?.theme?.title}
        </span>
        <div className={css.TestsContainer}>
          {questionGroups.map((el: IQuestionGroup) => (
            <OneTest test={el} key={el.id} />
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};
export default Tests;
